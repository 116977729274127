import React from 'react'

export const ZkSyncSVG = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="none" viewBox="0 0 240 48">
      <g clipPath="url(#clip0_180_26899)">
        <path
          fill="#4E529A"
          fillRule="evenodd"
          d="M83.308 23.558L59.675.008v17.247L36.212 34.523l23.465.016v12.569l23.632-23.55z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#8C8DFC"
          fillRule="evenodd"
          d="M-.003 23.55L23.63 47.099V29.99l23.465-17.406-23.465-.017V0L-.003 23.55z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.9"
          d="M118.517 35.319H100.43v-5.195l10.354-11.542h-10.088v-5.105h17.643v4.927L107.674 30.17h10.843v5.15zM143.201 13.476l-8.754 9.19 8.932 12.652h-7.244l-5.777-8.346-2.444 2.575v5.771h-5.911V3.177h5.911V21.6l7.555-8.124h7.732zM168.909 11.345l-5.51 1.687c-.208-1.124-.771-2.145-1.689-3.063-.919-.917-2.267-1.376-4.044-1.376-1.452 0-2.637.4-3.556 1.198-.918.77-1.377 1.702-1.377 2.797 0 1.924 1.14 3.108 3.422 3.552l4.399.843c2.785.533 4.948 1.643 6.489 3.33 1.54 1.687 2.311 3.67 2.311 5.949 0 2.604-1.037 4.883-3.111 6.836-2.045 1.924-4.8 2.886-8.266 2.886-1.985 0-3.778-.296-5.378-.888-1.6-.592-2.888-1.376-3.866-2.353-.978-1.006-1.748-2.057-2.311-3.152a10.487 10.487 0 01-.978-3.462l5.689-1.51c.148 1.687.8 3.093 1.955 4.218 1.185 1.124 2.83 1.687 4.933 1.687 1.6 0 2.844-.356 3.733-1.066.919-.71 1.378-1.643 1.378-2.797 0-.917-.341-1.702-1.022-2.353-.652-.68-1.585-1.139-2.8-1.376l-4.4-.888c-2.548-.532-4.562-1.583-6.044-3.152-1.481-1.568-2.222-3.522-2.222-5.86 0-2.782 1.067-5.12 3.2-7.014 2.163-1.894 4.755-2.841 7.777-2.841 1.807 0 3.422.251 4.844.754 1.422.504 2.563 1.184 3.422 2.043.859.828 1.526 1.687 2 2.574.474.888.815 1.82 1.022 2.797z"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.9"
          d="M179.311 43.931h-6.266l5.155-11.365-9.288-19.09h6.622l5.822 12.786 5.377-12.785h6.311L179.311 43.93zM201.202 22.755v12.564h-5.911V13.476h5.733v2.709c.622-1.066 1.51-1.88 2.666-2.442a8.226 8.226 0 013.644-.844c2.578 0 4.534.814 5.866 2.442 1.364 1.598 2.044 3.67 2.044 6.215V35.32h-5.91V22.577c0-1.302-.34-2.353-1.022-3.152-.652-.799-1.66-1.198-3.022-1.198-1.244 0-2.236.429-2.978 1.287-.74.858-1.11 1.939-1.11 3.241zM229.732 18.271c-1.57 0-2.888.547-3.956 1.643-1.066 1.095-1.6 2.59-1.6 4.483 0 1.895.534 3.404 1.6 4.529 1.096 1.095 2.43 1.642 4 1.642 1.392 0 2.504-.355 3.334-1.065.83-.74 1.392-1.613 1.688-2.62l5.2 1.732c-.534 2.013-1.688 3.744-3.466 5.194-1.778 1.45-4.03 2.175-6.756 2.175-3.258 0-6-1.095-8.222-3.285-2.192-2.22-3.288-4.987-3.288-8.301 0-3.345 1.082-6.112 3.244-8.302 2.192-2.19 4.888-3.286 8.088-3.286 2.786 0 5.066.726 6.844 2.176 1.778 1.42 2.918 3.152 3.422 5.194l-5.288 1.776c-.712-2.457-2.326-3.685-4.844-3.685z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_180_26899">
          <path fill="#fff" d="M0 0H240V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
